import { Box } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { firestoreDb } from "../firebase/firebaseConfig";

export const UsePendingPost = (
  userUid,
  isEditPost,
  editPostUid,
  setIsPreListPropertyScreen,
  postPropertyInfoDispatch,
  mediaDispatch
) => {
  const [pendingPostInfo, setPendingPostInfo] = useState({
    isPendingPostFetching: false,
    isPendingPostModalOpen: false,
    isPendingPost: false,
    pendingPostUid: null,
    pendingPostRef: null,
  });

  useEffect(() => {
    (async () => {
      setPendingPostInfo({
        ...pendingPostInfo,
        isPendingPostFetching: true,
      });
      try {
        // throw new Error("Error getting dashboard documents:");
        const q = query(
          collection(firestoreDb, "posts"),
          where("ownerUid", "==", userUid),
          where("paymentStatus", "==", "pending")
        );
        const editPostQuery = query(
          collection(firestoreDb, "posts"),
          where("ownerUid", "==", userUid),
          where("paymentStatus", "==", "success"),
          where("postUid", "==", editPostUid ? editPostUid : "")
        );
        const querySnapshot = isEditPost
          ? await getDocs(editPostQuery)
          : await getDocs(q);

        if (!querySnapshot?.empty) {
          const pendingPostDataRes = querySnapshot?.docs[0].data();
          postPropertyInfoDispatch({
            type: "fetch",
            value: pendingPostDataRes,
          });
          mediaDispatch({
            activity: "fetchMedia",
            value: pendingPostDataRes?.media,
          });
          setPendingPostInfo({
            isPendingPostFetching: false,
            isPendingPostModalOpen: isEditPost ? false : true,
            isPendingPost: true,
            pendingPostUid: pendingPostDataRes?.postUid,
            pendingPostTime: pendingPostDataRes?.postTime,
            pendingPostRef: querySnapshot?.docs[0].ref,
            pendingPostOwnerActions: pendingPostDataRes?.ownerActions,
          });
          setIsPreListPropertyScreen(false);
        } else {
          // console.log("No pending document - mylogs");
          setPendingPostInfo({
            ...pendingPostInfo,
            isPendingPostFetching: false,
          });
        }
      } catch (err) {
        console.error("err while getting my posts - mylogs :", err);
        setPendingPostInfo({
          ...pendingPostInfo,
          isPendingPostFetching: false,
        });
      }
    })();
  }, []);

  return { pendingPostInfo, setPendingPostInfo };
};
