import {
  and,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { mediaSorted } from "../constants/constants";
import { firestoreDb, messaging, storage } from "../firebase/firebaseConfig";
import { getToken } from "firebase/messaging";
import {
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

export const shareProperty = async (postUid) => {
  if (navigator.share) {
    try {
      // throw new Error("Error in sharing - mylogs");
      await navigator.share({
        text: "Okhla Homes\nCheck out this property\n",
        url: `${window.location.origin}/view-property/${postUid}`,
      });
    } catch (error) {
      console.error("Error in sharing - mylogs", error);
      navigator.clipboard.writeText(
        `Okhla Homes\nCheck out this property\n${window.location.origin}/view-property/${postUid}`
      );
    }
  } else {
    navigator.clipboard.writeText(
      `Okhla Homes\nCheck out this property\n${window.location.origin}/view-property/${postUid}`
    );
  }
};

export const mediaLength = (media) => {
  let length = 0;
  mediaSorted?.map((category, i) => {
    length += media[category]?.length;
  });
  return length;
};

export const addingDefaultUserData = async (uid, email) => {
  const userRef = doc(firestoreDb, "users", uid);

  try {
    // throw new Error("error while adding user data - Login.js - mylogs :");
    await setDoc(userRef, {
      email,
    });
  } catch (err) {
    console.error("err while adding user default data - mylogs :", err);
  }
};

export const saveMessagingDeviceToken = (uid) => {
  getToken(messaging, {
    vapidKey:
      "BNCfPsaO9YcJg7ow9GtuJa_A4G8NFlUDJfD7E-D7a6PtcpAqgCC3Tu5eGJgVcOXGItZh7STj0WN2h4cT33VKQ_M",
  })
    .then((fcmToken) => {
      if (fcmToken) {
        updateDoc(doc(firestoreDb, `users/${uid}`), {
          fcmToken: fcmToken,
        }).catch((err) => {
          console.error(
            "err while saving fcm token - Login.js - mylogs :",
            err
          );
        });
      } else {
        console.log("No registration token available. - mylogs");
      }
    })
    .catch((err) => {
      console.error("An error occurred while retrieving token. - mylogs ", err);
    });
};

async function deleteFilesInDirectory(directoryRef) {
  const files = await listAll(directoryRef);
  const deletePromises = files.items.map((fileRef) => deleteObject(fileRef));
  await Promise.all(deletePromises);
}

export const deletePendingPosts = async (userUid) => {
  try {
    const q1 = query(
      collection(firestoreDb, "posts"),
      and(
        where("ownerUid", "==", userUid),
        where("paymentStatus", "==", "pending")
      )
    );

    const querySnapshot = await getDocs(q1);
    const docsDeletedRef = [];
    const postUids = [];
    querySnapshot.forEach((doc) => {
      docsDeletedRef.push(doc.ref);
      postUids.push(doc.data().postUid);
    });

    if (docsDeletedRef.length === 0) return;

    let counter = 0;
    let batch = writeBatch(firestoreDb);
    docsDeletedRef.forEach((docRef) => {
      batch.delete(docRef);
      counter++;
      if (counter === 500) {
        batch.commit();
        batch = writeBatch(firestoreDb);
        counter = 0;
      }
    });

    await Promise.all([
      batch.commit(),
      postUids?.map((postUid) => {
        const directoryRef = ref(storage, `images/${userUid}/${postUid}`);
        return deleteFilesInDirectory(directoryRef);
      }),
    ]);
    console.log("Successfully deleted pending posts - mylogs");
  } catch (err) {
    console.error(
      "err while deleting pending posts - ListProperty.js - mylogs :",
      err
    );
  }
};

export const deletePendingPost = async (userUid, pendingPostInfo) => {
  if (!pendingPostInfo?.isPendingPost) {
    return;
  }

  try {
    const directoryRef = ref(
      storage,
      `images/${userUid}/${pendingPostInfo?.pendingPostUid}`
    );

    const contactInfoRef = doc(
      firestoreDb,
      `posts/${pendingPostInfo?.pendingPostUid}/secure/contact-info`
    );
    const orderInfoRef = doc(
      firestoreDb,
      `posts/${pendingPostInfo?.pendingPostUid}/secure/order-info`
    );

    await Promise.all([
      deleteDoc(pendingPostInfo?.pendingPostRef),
      deleteDoc(contactInfoRef),
      deleteFilesInDirectory(directoryRef),
      deleteDoc(orderInfoRef).catch((err) => {}),
    ]);
    console.log("pending post deleted - mylogs");
  } catch (err) {
    console.error("err while deleting pending post - mylogs :", err);
  }
};

const postPropertyDeepEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true;
  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  )
    return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (let key of keys1) {
    if (!keys2.includes(key)) return false;
    if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
      if (!postPropertyDeepEqual(obj1[key], obj2[key])) return false;
    } else {
      if (obj1[key] !== obj2[key]) return false;
    }
  }

  return true;
};

const mediaDeepEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true;

  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (let key of keys1) {
    if (!keys2.includes(key) || !mediaDeepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

export const hasChanges = (
  initialPostPropertyInfo,
  postPropertyInfo,
  media,
  initailMedia
) => {
  return (
    !postPropertyDeepEqual(initialPostPropertyInfo, postPropertyInfo) ||
    !mediaDeepEqual(media, initailMedia)
  );
};

export const checkValidity = (postPropertyInfo, media) => {
  for (const key in postPropertyInfo) {
    if (
      postPropertyInfo[key] === null ||
      postPropertyInfo[key] === "" ||
      postPropertyInfo[key] === undefined
    ) {
      if (key !== "amenities") {
        return false;
      }
    }
    if (
      typeof postPropertyInfo[key] === "object" &&
      !Array.isArray(postPropertyInfo[key])
    ) {
      for (const subKey in postPropertyInfo[key]) {
        if (
          postPropertyInfo[key][subKey] === null ||
          postPropertyInfo[key][subKey] === undefined
        ) {
          return false;
        }
      }
    }
  }

  if (postPropertyInfo.furnishment !== "Unfurnished") {
    if (postPropertyInfo?.amenities?.length === 0) {
      return false;
    }
  }

  if (postPropertyInfo.montlyRent < 5000) {
    return false;
  }

  const { hall, bedrooms, kitchen, bathroom } = media?.uploadedMedia;
  const isMediaNotEmpty = [hall, bedrooms, kitchen, bathroom].every(
    (mediaArray) => Array.isArray(mediaArray) && mediaArray.length > 0
  );
  if (!isMediaNotEmpty) {
    return false;
  }

  return true;
};
