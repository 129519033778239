import { Box, Button, List, ListItem, Stack, Typography } from "@mui/material";
import { Spacer } from "./Spacer";
import { primaryColor } from "../utils/color";
import { Deblur } from "@mui/icons-material";
import {
  POST_CHARGE_PERCENTAGE,
  POST_DURATION_MONTHS,
} from "../constants/constants";

export const PreListPropertyScreen = ({ setIsPreListPropertyScreen }) => {
  const howItWorksPoints = [
    'Choose Property Post Type: Select "Direct Calling" or "Request Submission" for how tenants will contact you.',
    "Add Basic Property Details: Provide essential information about your property and upload striking images to attract tenants.",
    `List Your Property: Post your property at some service charge of rent amount for ${POST_DURATION_MONTHS} months. If you have a promo code? Apply it, to reduce service charge.`,
    "Receive Inquiries: You will start getting calls or requests based on the chosen post type from interested tenants.",
    `Agreement: Once a tenant agrees to lease your property, seamlessly include the service charge alongside the rent charge.`,
    "Remove Property Post: After successfully leasing your property, Kindly take a moment to remove your property post to avoid receiving any further calls or requests.",
  ];

  return (
    <>
      <Stack justifyContent="center" alignItems="center">
        <Spacer space={40} />
        <Stack alignItems="center">
          <Typography
            sx={{
              color: "black",
              fontWeight: "300",
              fontSize: "28px",
              fontFamily: "Calistoga, serif",
            }}
          >
            Post Your Property Free
          </Typography>
          <Spacer space={4} />
          <Typography
            sx={{
              color: primaryColor[800],
              fontWeight: "600",
              fontSize: "18px",
              fontFamily: "'Satisfy', cursive",
            }}
          >
            At Returnable Service Charge of Rent Amount
          </Typography>
        </Stack>

        <Box>
          <img
            width="280px"
            height="280px"
            alt=""
            src={`${process.env.PUBLIC_URL}/illustrations/pre-list-property.svg`}
          />
        </Box>

        <Spacer space={10} />

        <Stack alignItems="center">
          <Typography
            sx={{
              color: primaryColor[800],
              fontWeight: "600",
              fontSize: "18px",
              fontFamily: "'Roboto Serif', serif",
            }}
          >
            How it works
          </Typography>
          <List disablePadding={true} dense={true}>
            {howItWorksPoints?.map((point, i) => (
              <ListItem key={i} sx={{ paddingY: "1px" }}>
                <Stack direction="row" alignItems="flex-start" spacing={1.5}>
                  <Deblur sx={{ fontSize: "18px", color: primaryColor[700] }} />
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontFamily: "'Roboto Serif', serif",
                      fontSize: "14px",
                      lineHeight: "18p",
                      textAlign: "justify",
                    }}
                  >
                    {point}
                  </Typography>
                </Stack>
              </ListItem>
            ))}
          </List>
        </Stack>

        <Spacer space={80} />

        <Button
          variant="contained"
          sx={{
            position: "fixed",
            bottom: "20px",
            width: "90%",
          }}
          onClick={() => setIsPreListPropertyScreen(false)}
        >
          Continue
        </Button>
      </Stack>
    </>
  );
};
