import { Box } from "@mui/material";
import { ViewPropertyHeader } from "../components/ViewPropertyHeader";
import { primaryBgColor } from "../utils/color";
import { useContext, useEffect, useReducer, useState } from "react";
import { ProfileContext } from "../context/ProfileContextProvider";
import { useLocation, useParams } from "react-router-dom";
import { SomethingWentWrongScreen } from "../components/something-went-wrong-screen";
import { NoPropertyFound } from "../components/NoPropertyFound";
import { UsePostDataInfo } from "../hooks/use-post-data-info";
import { UseRequestInfo } from "../hooks/use-request-info";
import { TextLoader } from "../components/text-loader";
import { BackdropLoader } from "../components/backdrop-loader";
import { CongratulationModal } from "../modals/congratulation";
import { SomethingWentWrongModal } from "../modals/someting-went-wrong";
import { RequestFormModal } from "../modals/request-form";
import { FullScreenImageCarousel } from "../components/full-screen-image-carousel";
import { RequestStatusBookmark } from "../components/request-status-bookmark";
import { ViewPropertyBody } from "../components/view-property-body";
import { ViewPropertyFooter } from "../components/view-property-footer";
import { PostNotAvailable } from "../components/post-not-available";
import { FullScreenInstaReel } from "../components/full-screen-insta-reel";
import { PostStatusBookmark } from "../components/post-status-bookmark";
import { UseSavedInfo } from "../hooks/use-saved-info";
import { UseRequestInfoReducer } from "../hooks/use-request-info-reducer";

export const ViewProperty = () => {
  const location = useLocation();
  const { navigatedData, navigatedFlag } = location.state || {};
  const statePostData = navigatedData?.postInfo;
  const isOwnerNavigatedFromMyPosts =
    navigatedFlag?.isOwnerNavigatedFromMyPosts;
  const params = useParams();
  const postUidFromParams = params?.id;
  const [isCallNowModalOpen, setIsCallNowModalOpen] = useState(false);
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [isInstaReelOpen, setIsInstaReelOpen] = useState(false);
  const { user } = useContext(ProfileContext);
  const { userInfo, isUserSignedIn, userData } = user;
  const [mediaIndexCount, setMediaIndexCount] = useState(1);
  const { requestInfo, requestInfoDispatch } =
    UseRequestInfoReducer(isRequestModalOpen);
  const { postDataRes } = UsePostDataInfo(statePostData, postUidFromParams);
  const isOwnerViewPost = userInfo.uid === postDataRes?.postData?.ownerUid;
  const { savedInfoRes, handleAddFavorite, handleRemvoeFavorite } =
    UseSavedInfo(userInfo.uid, postDataRes?.postData, isUserSignedIn);
  const { requestRes, setRequestRes, handleRequestSend, handleDeleteRequest } =
    UseRequestInfo(
      postDataRes?.postData,
      userInfo.uid,
      isUserSignedIn,
      isOwnerViewPost,
      requestInfo,
      setIsCallNowModalOpen,
      setIsRequestModalOpen
    );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (postDataRes?.isLoading) {
    return <TextLoader text="Loading..." />;
  }

  if (postDataRes?.isError) {
    return <SomethingWentWrongScreen />;
  }

  if (postDataRes?.isEmpty) {
    return <NoPropertyFound />;
  }

  // if () {
  //   return <PostNotAvailable />;
  // }

  if (!postDataRes?.postData) {
    return <></>;
  }

  if (isSliderOpen) {
    return (
      <FullScreenImageCarousel
        media={postDataRes?.postData?.media}
        mediaIndexCount={mediaIndexCount}
        setMediaIndexCount={setMediaIndexCount}
        setIsSliderOpen={setIsSliderOpen}
        postStatus={postDataRes?.postData?.postStatus}
      />
    );
  }

  if (isInstaReelOpen) {
    return (
      <FullScreenInstaReel
        setIsInstaReelOpen={setIsInstaReelOpen}
        instaUrl={postDataRes?.postData?.videosMedia?.instaUrl}
      />
    );
  }

  return (
    <>
      <BackdropLoader isLoading={requestRes?.isHandlingRequestSend} />
      <CongratulationModal
        value="handlingRequestSendSuccessStatus"
        state={requestRes}
        setState={setRequestRes}
        text="Your request is sent successfully. Once your request is accepted
              by the owner, you can contact each other directly."
      />
      <SomethingWentWrongModal
        value="handlingRequestSendFailedStatus"
        state={requestRes}
        setState={setRequestRes}
      />
      <SomethingWentWrongModal
        value="deletingRequestFailedStatus"
        state={requestRes}
        setState={setRequestRes}
      />
      <RequestFormModal
        requestInfo={requestInfo}
        requestInfoDispatch={requestInfoDispatch}
        handleRequestSend={handleRequestSend}
        isRequestModalOpen={isRequestModalOpen}
        setIsRequestModalOpen={setIsRequestModalOpen}
      />

      <Box sx={{ bgcolor: `${primaryBgColor}`, position: "relative" }}>
        {/* <PostStatusBookmark
          postStatus={postDataRes?.postData?.postStatus}
          paymentStatus={postDataRes?.postData?.paymentStatus}
        /> */}
        <ViewPropertyHeader
          isOwnerViewPost={isOwnerViewPost}
          postData={postDataRes?.postData}
          savedInfoRes={savedInfoRes}
          handleAddFavorite={handleAddFavorite}
          handleRemvoeFavorite={handleRemvoeFavorite}
        />
        <RequestStatusBookmark
          status={requestRes?.requestData?.requestStatus}
          postStatus={postDataRes?.postData?.postStatus}
        />
        <ViewPropertyBody
          postData={postDataRes?.postData}
          mediaIndexCount={mediaIndexCount}
          setMediaIndexCount={setMediaIndexCount}
          setIsSliderOpen={setIsSliderOpen}
          setIsInstaReelOpen={setIsInstaReelOpen}
          isOwnerViewPost={isOwnerViewPost}
        />
        <ViewPropertyFooter
          postData={postDataRes?.postData}
          requestRes={requestRes}
          isOwnerViewPost={isOwnerViewPost}
          isOwnerNavigatedFromMyPosts={isOwnerNavigatedFromMyPosts}
          isCallNowModalOpen={isCallNowModalOpen}
          setIsCallNowModalOpen={setIsCallNowModalOpen}
          setIsRequestModalOpen={setIsRequestModalOpen}
          isUserSignedIn={isUserSignedIn}
          userContactNumber={userData?.contactNumber}
          handleDeleteRequest={handleDeleteRequest}
        />
      </Box>
    </>
  );
};
