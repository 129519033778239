import { useContext, useState } from "react";
import { ProfileContext } from "../context/ProfileContextProvider";
import { OtpScreen } from "../components/otp-screen";
import { MobileNumberScreen } from "../components/mobile-number-screen";
import { isDevMode } from "../utils/is-dev-mode";

export const MobieVerification = () => {
  const { user } = useContext(ProfileContext);
  const { userInfo } = user;

  const [screen, setScreen] = useState("1");
  const [verificationId, setVerificationId] = useState("");
  const [contactNumber, setContactNumber] = useState(
    isDevMode() ? "1234567890" : ""
  );

  if (screen === "2") {
    return (
      <OtpScreen
        userUid={userInfo?.uid}
        setScreen={setScreen}
        contactNumber={contactNumber}
        verificationId={verificationId}
      />
    );
  }

  return (
    <MobileNumberScreen
      contactNumber={contactNumber}
      setContactNumber={setContactNumber}
      setScreen={setScreen}
      setVerificationId={setVerificationId}
    />
  );
};
