import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { deletePendingPost } from "../helper/helper";
import { TitleTextActionActionModal } from "../modals/title-text-action-action";

export const BackwardDiscardModal = ({
  isDiscardModalOpen,
  setIsDiscardModalOpen,
  isPostDetailsChanges,
  pendingPostInfo,
  userUid,
  isEditPost,
  handleSavePost,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const handlePopstate = (event) => {
      if (!isPostDetailsChanges) {
        navigate("/");
        return;
      }

      setIsDiscardModalOpen({
        isOpen: true,
      });
      window.history.pushState(
        window?.history?.state,
        null,
        window.location.pathname
      );
    };

    if (!isEditPost) {
      window.history.pushState(
        window?.history?.state,
        null,
        window.location.pathname
      );
    }
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [isPostDetailsChanges]);

  const handleSaveChanges = () => {
    handleSavePost();
    setIsDiscardModalOpen({
      isOpen: false,
    });
    navigate("/");
  };

  const handleDiscardChanges = () => {
    deletePendingPost(userUid, pendingPostInfo);
    setIsDiscardModalOpen({
      isOpen: false,
    });
    navigate("/");
  };

  return (
    <>
      <TitleTextActionActionModal
        value="isOpen"
        state={isDiscardModalOpen}
        setState={setIsDiscardModalOpen}
        title="Save changes"
        text=" Do you want to save changes before leaving the page ?"
        actionText1="Discard"
        actionFunction1={handleDiscardChanges}
        actionText2="Save"
        actionFunction2={handleSaveChanges}
      />
    </>
  );
};
