import { Box, Typography } from "@mui/material";
import { green, grey, red } from "@mui/material/colors";
import { PAYMENT_STATUS, POST_STATUS } from "../constants/constants";

export const PostStatusBookmarkListCard = ({ postStatus }) => {
  if (postStatus === POST_STATUS.ACTIVE) {
    return <></>;
  }

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 12,
          left: 0,
          zIndex: 98,
          backgroundColor: getBookmarkBackgroundColor(postStatus),
          color: "white",
          width: "60px",
          textAlign: "center",
          transform: "rotate(0deg)",
          transformOrigin: "top left",
          padding: "2px 0",
          borderTopRightRadius: "4px",
          borderBottomRightRadius: "4px",
          paddingLeft: "3px",
          paddingRight: "3px",
        }}
      >
        <Typography sx={{ fontSize: "9px", fontWeight: "500" }}>
          {getBookmarkTitle(postStatus)}
        </Typography>
      </Box>
    </>
  );
};

const getBookmarkTitle = (status) => {
  switch (status) {
    case POST_STATUS.IN_REVIEW:
      return "In Review";
    case POST_STATUS.REMOVED:
      return "Removed";
    case POST_STATUS.EXPIRED:
      return "Expired";
    case PAYMENT_STATUS.FAILED:
      return "Payment Failed";
    case PAYMENT_STATUS.INVALID:
      return "Payment Invalid";
    default:
      return "";
  }
};

const getBookmarkBackgroundColor = (status) => {
  switch (status) {
    case POST_STATUS.IN_REVIEW:
      return green[700];
    case POST_STATUS.REMOVED:
      return red[700];
    case POST_STATUS.EXPIRED:
      return grey[700];
    case PAYMENT_STATUS.FAILED:
      return red[700];
    case PAYMENT_STATUS.INVALID:
      return red[700];
    default:
      return "";
  }
};
