import { createContext, useEffect, useState } from "react";
import {
  and,
  collection,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { firestoreDb } from "../firebase/firebaseConfig";

export const HomePostsContext = createContext();

const HomePostsContextProvider = ({ children }) => {
  const [homePosts, setHomePosts] = useState({
    isHomePostsLoading: true,
    isHomePostsError: false,
    homePostsInfo: {
      randomPropertyFirstBlock: [],
      randomPropertySecondBlock: [],
      recentlyAddedProperty: [],
      propertyUnder12000: [],
    },
  });

  const [triggerHomePosts, setTriggerHomePosts] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        // throw new Error("Error getting dashboard documents:");
        const q = query(
          collection(firestoreDb, "posts"),
          and(
            where("postStatus", "==", "active"),
            where("paymentStatus", "==", "success")
          )
        );
        const snapshot = await getDocs(q);

        if (snapshot.empty) {
          throw new Error("No Documents found in the collection.");
        }

        const allDocuments = snapshot.docs;

        const shuffledDocuments = allDocuments.sort(() => Math.random() - 0.5);
        const randomDocumentsFirstBlock = shuffledDocuments.slice(0, 3);
        const randomPropertyFirstBlock = [];
        randomDocumentsFirstBlock.forEach((doc) => {
          randomPropertyFirstBlock.push(doc.data());
        });
        // console.log(
        //   "randomPropertyFirstBlock - PropertyCardSection.js - mylogs :",
        //   randomPropertyFirstBlock
        // );

        const randomDocumentsSecondBlock = shuffledDocuments.slice(-3);
        const randomPropertySecondBlock = [];
        randomDocumentsSecondBlock.forEach((doc) => {
          randomPropertySecondBlock.push(doc.data());
        });
        // console.log(
        //   "randomPropertySecondBlock - PropertyCardSection.js - mylogs :",
        //   randomPropertySecondBlock
        // );

        const sortedDocuments = allDocuments.sort((a, b) => {
          return b.data()?.postTime?.toDate() - a.data()?.postTime?.toDate();
        });
        const sliceSortedDocuments = sortedDocuments.slice(0, 4);
        const recentlyAddedProperty = [];
        sliceSortedDocuments.forEach((doc) => {
          recentlyAddedProperty.push(doc.data());
        });
        // console.log(
        //   "recentlyAddedProperty - PropertyCardSection.js - mylogs :",
        //   recentlyAddedProperty
        // );

        const propertyUnder12000 = [];
        allDocuments.forEach((doc) => {
          const document = doc.data();
          if (document.postInfo.montlyRent <= 12000) {
            propertyUnder12000.push(document);
          }
        });
        // console.log(
        //   "propertyUnder12000 - PropertyCardSection.js - mylogs :",
        //   propertyUnder12000
        // );

        setHomePosts({
          isHomePostsLoading: false,
          isHomePostsError: false,
          homePostsInfo: {
            randomPropertyFirstBlock,
            randomPropertySecondBlock,
            recentlyAddedProperty,
            propertyUnder12000,
          },
        });
      } catch (error) {
        console.error("Error getting dashboard documents:", error);
        setHomePosts({
          isHomePostsLoading: false,
          isHomePostsError: true,
          homePostsInfo: {
            randomPropertyFirstBlock: [],
            randomPropertySecondBlock: [],
            recentlyAddedProperty: [],
            propertyUnder12000: [],
          },
        });
      }
    })();
  }, [triggerHomePosts]);

  return (
    <>
      <HomePostsContext.Provider
        value={{ ...homePosts, triggerHomePosts, setTriggerHomePosts }}
      >
        {children}
      </HomePostsContext.Provider>
    </>
  );
};

export default HomePostsContextProvider;
