import { Box, List } from "@mui/material";
import { useContext } from "react";
import { ProfileContext } from "../context/ProfileContextProvider";
import { SomethingWentWrongScreen } from "../components/something-went-wrong-screen";
import { ListPropertyCard } from "../components/ListPropertyCard";
import { UseMysavedInfo } from "../hooks/use-mysaved-info";
import { TextLoader } from "../components/text-loader";
import { ExplorePropertyScreen } from "../components/explore-property-screen";
import { MySavedHeader } from "../components/my-saved-header";

export const MySaved = () => {
  const { user } = useContext(ProfileContext);
  const { mySavedProperties } = UseMysavedInfo(user?.userInfo?.uid);
  // console.log("mySavedProperties - MySaved.js - mylogs :", mySavedProperties);

  if (mySavedProperties?.isLoading) {
    return <TextLoader text="Loading..." />;
  }

  if (mySavedProperties?.isError) {
    return <SomethingWentWrongScreen />;
  }

  if (mySavedProperties?.data?.length === 0) {
    return (
      <>
        <MySavedHeader />
        <ExplorePropertyScreen />
      </>
    );
  }

  return (
    <>
      <Box>
        <MySavedHeader />
        <Box>
          <List sx={{ paddingY: "0px" }}>
            {mySavedProperties?.data?.map((property, i) => (
              <ListPropertyCard key={i} request={property} />
            ))}
          </List>
        </Box>
      </Box>
    </>
  );
};
