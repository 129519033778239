import { useEffect } from "react";

export const RestrictPageReload = ({ restrictConditionArr }) => {
  const isDiscard = restrictConditionArr.some((cond) => cond);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    if (isDiscard) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, restrictConditionArr);

  return <></>;
};
