import { Box, Stack } from "@mui/material";
import { FullScreen } from "./FullScreen";
import "./logo-loader.css";

export const LogoLoader = ({ text }) => {
  return (
    <>
      <Box>
        <FullScreen>
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <div class="loader2"></div>
            <div class="loader"></div>
          </Stack>
        </FullScreen>
      </Box>
    </>
  );
};
