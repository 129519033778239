import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
} from "@firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { firestoreDb } from "../firebase/firebaseConfig";
import { ProfileContext } from "./ProfileContextProvider";
import { useLocation } from "react-router";
import { PAYMENT_STATUS, POST_STATUS } from "../constants/constants";

export const MyPostsContext = createContext();

export const MyPostsContextProvider = ({ children }) => {
  const { user } = useContext(ProfileContext);
  const { userInfo, isUserSignedIn } = user;

  const [myPostsRes, setMyPostsRes] = useState({
    isError: false,
    isLoading: true,
    myPostsData: {
      activePosts: [],
      archivePosts: [],
    },
  });
  const [triggerMyPostsRes, setTriggerMyPostsRes] = useState(false);
  const [triggerMyPosts, setTriggerMyPosts] = useState(false);

  useEffect(() => {
    (async () => {
      if (isUserSignedIn && triggerMyPostsRes) {
        try {
          // throw new Error("Error getting dashboard documents:");
          const q = query(
            collection(firestoreDb, "posts"),
            where("ownerUid", "==", userInfo?.uid),
            where("paymentStatus", "in", ["success", "failed", "invalid"]),
            orderBy("postTime", "desc")
          );
          const querySnapshot = await getDocs(q);
          const myPostsFilterRes = [];
          querySnapshot.forEach((doc) => {
            myPostsFilterRes.push(doc.data());
          });

          const activePosts = [];
          const archivePosts = [];
          myPostsFilterRes.forEach((doc) => {
            if (
              doc?.paymentStatus === PAYMENT_STATUS.SUCCESS &&
              (doc?.postStatus === POST_STATUS.ACTIVE ||
                doc?.postStatus === POST_STATUS.IN_REVIEW ||
                doc?.postStatus === POST_STATUS.ACTION_REQUIRED)
            ) {
              activePosts.push(doc);
            } else {
              archivePosts.push(doc);
            }
          });

          setMyPostsRes({
            isError: false,
            isLoading: false,
            myPostsData: {
              activePosts: activePosts,
              archivePosts: archivePosts,
            },
          });
        } catch (err) {
          console.error(
            "err while getting my posts - FilterActionSheet.js - mylogs :",
            err
          );
          setMyPostsRes({
            isError: true,
            isLoading: false,
            myPostsData: {
              activePosts: [],
              archivePosts: [],
            },
          });
        }
      } else {
        setMyPostsRes({
          isError: false,
          isLoading: true,
          myPostsData: {
            activePosts: [],
            archivePosts: [],
          },
        });
      }
    })();
  }, [user, triggerMyPostsRes, triggerMyPosts]);

  return (
    <>
      <MyPostsContext.Provider
        value={{
          myPostsRes,
          triggerMyPostsRes,
          setTriggerMyPostsRes,
          triggerMyPosts,
          setTriggerMyPosts,
        }}
      >
        {children}
      </MyPostsContext.Provider>
    </>
  );
};
