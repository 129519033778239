import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { FullScreen } from "./FullScreen";
import PostFailureIllustration from "../illustrations/post-failure.svg";
import { primaryColor } from "../utils/color";
import { Link } from "react-router-dom";
import { MyPostsContext } from "../context/MyPostsContextProvider";
import { useContext, useEffect } from "react";
import { Spacer } from "./Spacer";

export const PropertyPostFalilure = ({ razorpayIds }) => {
  const { triggerMyPostsRes, setTriggerMyPostsRes } =
    useContext(MyPostsContext);

  useEffect(() => {
    setTriggerMyPostsRes(!triggerMyPostsRes);
  }, []);

  return (
    <>
      <FullScreen spacing={0}>
        <Stack alignItems="center">
          <Typography
            sx={{
              color: primaryColor[800],
              fontFamily: "Calistoga, serif",
              fontSize: "42px",
              fontWeight: "600",
            }}
          >
            Payment Failed !
          </Typography>
          <Typography
            sx={{
              color: "black",
              fontSize: "24px",
              fontWeight: "500",
              fontFamily: "'Satisfy', cursive",
            }}
          >
            Don't worry your money is safe.
          </Typography>
        </Stack>

        <Box>
          <img
            width="220px"
            height="220px"
            alt=""
            src={PostFailureIllustration}
          />
        </Box>

        <Box sx={{ paddingX: "20px" }}>
          <Stack>
            <Stack>
              <Typography sx={testStyling}>
                Order ID : {razorpayIds?.orderId}
              </Typography>
              <Typography sx={testStyling}>
                Payment ID : {razorpayIds?.paymentId}
              </Typography>
            </Stack>
            <Spacer space={10} />
            <Divider sx={{ bgcolor: primaryColor[800] }} />
            <Spacer space={10} />
            <Typography sx={testStyling}>
              If money was debited from your account, it will be refunded within
              7-10 working days.
            </Typography>
          </Stack>
        </Box>

        <Button
          variant="contained"
          sx={{ width: "90%" }}
          component={Link}
          to="/"
        >
          Go to Home page
        </Button>
      </FullScreen>
    </>
  );
};

const testStyling = {
  fontWeight: "500",
  textAlign: "center",
  fontFamily: "'Roboto Serif', serif",
  fontSize: "14px",
  lineHeight: "18p",
};
