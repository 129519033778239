import { Button, Stack, Typography } from "@mui/material";
import { FullScreen } from "../components/FullScreen";
import { primaryColor } from "../utils/color";
import pageNotFoundIllustration from "../illustrations/page-not-found.svg";
import { Link } from "react-router-dom";

export const NoPageFound = () => {
  return (
    <>
      <FullScreen spacing={3}>
        <Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Typography
              sx={{
                color: "black",
                fontWeight: "300",
                fontSize: "36px",
                fontFamily: "Calistoga, serif",
              }}
            >
              Page
            </Typography>
            <Typography
              sx={{
                color: primaryColor[800],
                fontWeight: "300",
                fontSize: "36px",
                fontFamily: "Calistoga, serif",
              }}
            >
              Not Found
            </Typography>
          </Stack>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "500",
              fontFamily: "'Satisfy', cursive",
            }}
          >
            The page you are looking for doesn't exist
          </Typography>
        </Stack>

        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            bgcolor: primaryColor[100],
            borderRadius: "50%",
            width: "250px",
            height: "150px",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "0px",
            paddingBottom: "75px",
          }}
        >
          <img
            width="280px"
            height="280px"
            alt=""
            src={pageNotFoundIllustration}
          />
        </Stack>

        <Button
          variant="contained"
          sx={{ width: "90%" }}
          component={Link}
          to="/"
        >
          Go to Home page
        </Button>
      </FullScreen>
    </>
  );
};
