import { Alert, Box } from "@mui/material";
import { Spacer } from "./Spacer";
import { Bookmark } from "@mui/icons-material";
import { POST_STATUS, requestStatus } from "../constants/constants";

export const RequestStatusBookmark = ({ status, postStatus }) => {
  const isPostActive = postStatus === POST_STATUS.ACTIVE;

  if (!status) {
    return <></>;
  }

  return (
    <>
      <Box sx={{ opacity: isPostActive ? "1" : "0.5" }}>
        <Spacer space={12} />
        <Alert
          icon={<Bookmark fontSize="inherit" />}
          variant="filled"
          severity={requestStatusSeverityMap[status]}
          sx={{
            width: "50%",
            height: "35px",
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
            paddingY: "0px",
            ".MuiAlert-message": {
              overflow: "unset",
            },
          }}
        >
          Request {requestStatusMap[status]}
        </Alert>
      </Box>
    </>
  );
};

const requestStatusSeverityMap = {
  [requestStatus.approved]: "success",
  [requestStatus.pending]: "info",
  [requestStatus.rejected]: "error",
};

const requestStatusMap = {
  [requestStatus.approved]: "Approved",
  [requestStatus.pending]: "Pending",
  [requestStatus.rejected]: "Rejected",
};
