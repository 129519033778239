import { Box, Stack, Typography } from "@mui/material";
import { FullScreen } from "../components/FullScreen";
import { primaryColor } from "../utils/color";
import OfflineConnectionIllustration from "../illustrations/offline-connection.svg";

export const Offline = () => {
  return (
    <>
      <FullScreen spacing={1}>
        <Stack alignItems="center">
          <Typography
            sx={{
              color: primaryColor[800],
              fontFamily: "Ultra, serif",
              fontSize: "32px",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            You are offline !!
          </Typography>
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "500",
              fontFamily: "'Satisfy', cursive",
            }}
          >
            Please check your internet connection
          </Typography>
        </Stack>

        <img
          width="240px"
          height="240px"
          alt=""
          src={OfflineConnectionIllustration}
        />

        <Box sx={{ height: "40px" }}></Box>
      </FullScreen>
    </>
  );
};
