import { Box, Button } from "@mui/material";
import { FullScreen } from "./FullScreen";
import listPropertyIllustration from "../illustrations/list-property.svg";
import { Link } from "react-router-dom";

export const ListPropertyScreen = () => {
  return (
    <>
      <Box>
        <FullScreen spacing={6}>
          <Box></Box>
          <Box>
            <img
              width="260px"
              height="260px"
              alt=""
              src={listPropertyIllustration}
            />
          </Box>
          <Button
            variant="contained"
            sx={{ width: "90%" }}
            component={Link}
            to="/list-property"
          >
            List Your Property
          </Button>
        </FullScreen>
      </Box>
    </>
  );
};
