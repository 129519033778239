import { Box, Button, Stack, Typography } from "@mui/material";
import { MainPropertyCard } from "./MainPropertyCard";
import { Center } from "./Center";
import { Spacer } from "./Spacer";
import NoSearchFilterResultIllustration from "../illustrations/no-search-filter-result.svg";

export const FilterResults = ({ filterResults, setMainFilterInfoProvider }) => {
  return (
    <>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ paddingX: "18px" }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            {filterResults.length} Results
          </Typography>
          <Button onClick={() => setMainFilterInfoProvider(null)}>clear</Button>
        </Stack>
        <Spacer space={10} />
        {filterResults.length === 0 ? (
          <Center>
            <Box>
              <img
                width="260px"
                height="260px"
                alt=""
                src={NoSearchFilterResultIllustration}
              />
            </Box>
          </Center>
        ) : (
          <Box sx={{ marginX: "12px" }}>
            <Stack spacing={3}>
              {filterResults.map((postData, i) => (
                <MainPropertyCard
                  key={i}
                  postData={postData}
                  isOwnerNavigatedFromMyPosts={false}
                />
              ))}
            </Stack>
          </Box>
        )}
      </Box>
    </>
  );
};
