import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { FullScreen } from "./FullScreen";
import PostManCongratulation from "../illustrations/post-man-congratulation.svg";
import { primaryColor } from "../utils/color";
import { Link } from "react-router-dom";
import { MyPostsContext } from "../context/MyPostsContextProvider";
import { useContext, useEffect } from "react";
import { Spacer } from "./Spacer";

export const PropertyPostCongratulation = ({ razorpayIds, isFreePost }) => {
  const { triggerMyPostsRes, setTriggerMyPostsRes } =
    useContext(MyPostsContext);

  useEffect(() => {
    setTriggerMyPostsRes(!triggerMyPostsRes);
  }, []);

  return (
    <>
      <FullScreen spacing={-3}>
        <Stack alignItems="center">
          <Typography
            sx={{
              color: primaryColor[800],
              fontFamily: "Ultra, serif",
              fontSize: "32px",
              fontWeight: "500",
            }}
          >
            Congratulations
          </Typography>
          <Typography
            sx={{
              color: "black",
              fontSize: "24px",
              fontWeight: "500",
              fontFamily: "'Satisfy', cursive",
            }}
          >
            Your post is successfully posted.
          </Typography>
        </Stack>

        <Box>
          <img
            width="300px"
            height="320px"
            alt=""
            src={PostManCongratulation}
          />
        </Box>

        <Box sx={{ width: "100%" }}>
          <Stack sx={{ paddingX: "20px" }}>
            {!isFreePost && (
              <Stack alignItems="center">
                <Stack alignItems="flex-start">
                  <Typography sx={testStyling}>
                    Order ID - {razorpayIds?.orderId}
                  </Typography>
                  <Typography sx={testStyling}>
                    Payment ID - {razorpayIds?.paymentId}
                  </Typography>
                </Stack>
              </Stack>
            )}
            <Spacer space={10} />
            <Divider sx={{ bgcolor: primaryColor[800] }} />
            <Spacer space={10} />
            <Typography sx={testStyling}>
              Thanks for using our service.
            </Typography>
          </Stack>
        </Box>

        <Button
          variant="contained"
          sx={{ width: "90%" }}
          component={Link}
          to="/"
          replace={true}
        >
          Go to Home Page
        </Button>
      </FullScreen>
    </>
  );
};

const testStyling = {
  fontWeight: "500",
  textAlign: "center",
  fontFamily: "'Roboto Serif', serif",
  fontSize: "14px",
  lineHeight: "18p",
};
