import { Button, Stack, Typography } from "@mui/material";
import { FullScreen } from "./FullScreen";
import { Link } from "react-router-dom";
import { primaryColor } from "../utils/color";
import ParachuteBoyFallingIllustration from "../illustrations/parachute-boy-falling.svg";
import { grey } from "@mui/material/colors";

export const SomethingWentWrongScreen = () => {
  return (
    <>
      <FullScreen spacing={1}>
        <Stack alignItems="center">
          <Typography
            sx={{
              color: primaryColor[800],
              fontFamily: "Ultra, serif",
              fontSize: "31px",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            Something Went Wrong !!
          </Typography>
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "500",
              fontFamily: "'Satisfy', cursive",
            }}
          >
            Please try again later sometime
          </Typography>
        </Stack>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            bgcolor: grey[200],
            borderRadius: "50%",
            height: "160px",
            padding: "25px 35px 35px 25px",
          }}
        >
          <img height="280px" alt="" src={ParachuteBoyFallingIllustration} />
        </Stack>
        <Button
          variant="contained"
          sx={{ width: "90%" }}
          component={Link}
          to="/"
        >
          Go to Home page
        </Button>
      </FullScreen>
    </>
  );
};
