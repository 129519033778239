import {
  addDoc,
  and,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { firestoreDb } from "../firebase/firebaseConfig";
import { useNavigate } from "react-router-dom";
import { SAVED_LIVE_STATUS } from "../constants/constants";
import { setSignInRedirectPath } from "../utils/generals";

export const UseSavedInfo = (userUid, postData, isUserSignedIn) => {
  const navigate = useNavigate();
  const [triggerSavedStatus, setTriggerSavedStatus] = useState(false);
  const [savedInfoRes, setSavedInfoRes] = useState({
    isError: false,
    isLoading: true,
    liveStatus: SAVED_LIVE_STATUS.UNSAVED,
    savedRefId: null,
  });

  const handleAddFavorite = async () => {
    if (!isUserSignedIn) {
      setSignInRedirectPath();
      navigate("/login");
      return;
    }

    if (savedInfoRes?.savedRefId) {
      try {
        setSavedInfoRes({
          ...savedInfoRes,
          isLoading: true,
        });
        await updateDoc(doc(firestoreDb, `saved`, savedInfoRes?.savedRefId), {
          liveStatus: SAVED_LIVE_STATUS.SAVED,
        });
        console.log("successfully saved property - mylogs");
        setTriggerSavedStatus(!triggerSavedStatus);
      } catch (err) {
        console.error(
          "err while saving property - ListProperty.js - mylogs :",
          err
        );
        setSavedInfoRes({
          ...savedInfoRes,
          isLoading: false,
        });
      }
      return;
    }

    const basicPostInfo = {
      montlyRent: postData?.postInfo?.montlyRent,
      fullName: postData?.postInfo?.fullName,
      locality: postData?.postInfo?.address?.locality,
      bhk: postData?.postInfo?.bhk,
      mainMedia: postData?.media?.hall[0] || postData?.media?.bedrooms[0],
    };

    try {
      setSavedInfoRes({
        ...savedInfoRes,
        isLoading: true,
      });
      await addDoc(collection(firestoreDb, `saved`), {
        time: new Date(),
        liveStatus: SAVED_LIVE_STATUS.SAVED,
        postUid: postData?.postUid,
        userUid,
        ownerUid: postData?.ownerUid,
        postStatus: postData?.postStatus,
        basicPostInfo,
      });
      console.log("successfully saved property - mylogs");
      setTriggerSavedStatus(!triggerSavedStatus);
    } catch (err) {
      console.error(
        "err while saving request - ListProperty.js - mylogs :",
        err
      );
      setSavedInfoRes({
        ...savedInfoRes,
        isLoading: false,
      });
    }
  };

  const handleRemvoeFavorite = async () => {
    try {
      setSavedInfoRes({
        ...savedInfoRes,
        isLoading: true,
      });
      await updateDoc(doc(firestoreDb, `saved`, savedInfoRes?.savedRefId), {
        liveStatus: SAVED_LIVE_STATUS.UNSAVED,
      });
      console.log("successfully removed saved property - mylogs");
      setTriggerSavedStatus(!triggerSavedStatus);
    } catch (err) {
      console.error(
        "err while removing request - ListProperty.js - mylogs :",
        err
      );
      setSavedInfoRes({
        ...savedInfoRes,
        isLoading: false,
      });
    }
  };

  useEffect(() => {
    if (!isUserSignedIn) {
      setSavedInfoRes({
        ...savedInfoRes,
        isLoading: false,
      });
      return;
    }

    if (!postData?.postUid) {
      return;
    }

    (async () => {
      try {
        const q = query(
          collection(firestoreDb, `saved`),
          and(
            where("postUid", "==", postData?.postUid),
            where("userUid", "==", userUid)
          )
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          setSavedInfoRes({
            ...savedInfoRes,
            isLoading: false,
            liveStatus: querySnapshot?.docs[0]?.data()?.liveStatus,
            savedRefId: querySnapshot?.docs[0]?.id,
          });
        } else {
          setSavedInfoRes({
            ...savedInfoRes,
            isLoading: false,
            liveStatus: SAVED_LIVE_STATUS.UNSAVED,
            savedRefId: null,
          });
        }
      } catch (err) {
        console.error(
          "err while fetching saved status - ViewPropertyHeader.js - mylogs :",
          err
        );
        setSavedInfoRes({
          ...savedInfoRes,
          isError: true,
          isLoading: false,
          liveStatus: SAVED_LIVE_STATUS.UNSAVED,
          savedRefId: null,
        });
      }
    })();
  }, [triggerSavedStatus, postData?.postUid]);

  return { savedInfoRes, handleAddFavorite, handleRemvoeFavorite };
};
