import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TitleTextActionActionModal } from "./title-text-action-action";

export const BackwardGeneralDiscardModal = () => {
  const navigate = useNavigate();
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState({
    isOpen: false,
  });

  useEffect(() => {
    const handlePopstate = (event) => {
      setIsDiscardModalOpen({
        isOpen: true,
      });
    };

    window.history.pushState(
      window?.history?.state,
      null,
      window.location.pathname
    );
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  const handleActionFunction1 = () => {
    window.history.pushState(
      window?.history?.state,
      null,
      window.location.pathname
    );
    setIsDiscardModalOpen({
      isOpen: false,
    });
  };

  const handleActionFunction2 = () => {
    navigate(-1);
  };

  return (
    <>
      <TitleTextActionActionModal
        value="isOpen"
        state={isDiscardModalOpen}
        setState={setIsDiscardModalOpen}
        title="Leave Page"
        text="Are you sure you want to leave this page?"
        actionText1="Cancel"
        actionFunction1={handleActionFunction1}
        actionText2="Leave"
        actionFunction2={handleActionFunction2}
      />
    </>
  );
};
