import { Box, Button, Stack, Typography } from "@mui/material";
import { Spacer } from "./Spacer";
import ParachuteBoyFallingIllustration from "../illustrations/parachute-boy-falling.svg";
import { primaryColor } from "../utils/color";
import { grey } from "@mui/material/colors";

export const ErrorParachuteComponent = () => {
  return (
    <>
      <Box>
        <Spacer space={25} />
        <Stack alignItems="center">
          <Stack alignItems="center">
            <Typography
              sx={{
                color: primaryColor[800],
                fontSize: "22px",
                fontWeight: "500",
                fontFamily: "Ultra, serif",
              }}
            >
              Something Went Wrong
            </Typography>
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: "500",
                fontFamily: "'Satisfy', cursive",
              }}
            >
              Please try again later
            </Typography>
          </Stack>
          <Spacer space={25} />
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              bgcolor: grey[200],
              borderRadius: "50%",
              height: "120px",
              padding: "35px",
              paddingLeft: "25px",
              paddingTop: "30px",
            }}
          >
            <img height="220px" alt="" src={ParachuteBoyFallingIllustration} />
          </Stack>
          <Spacer space={40} />
          <Button
            variant="contained"
            sx={{ width: "80%", height: "30px" }}
            onClick={() => window.location.reload()}
          >
            Refresh
          </Button>
          <Spacer space={10} />
        </Stack>
      </Box>
    </>
  );
};
